export default [
  {
    title: `Openingsfeest`,
    eventID: `opening`,
    color: `black`,
    date: "28 sep 2017",
    videos: [
      {
        title: `Aftermovie`,
        id: `1AE-asGI-tc`,
        placeholderVid: "/placeholder-vids/openingsfeest_loop.mp4",
        placeholderThumb: "/placeholder-vids/Openingsfeest.jpg",
      },
    ],
    downloads: [
      {
        title: `Alle foto's`,
        url: `https://drive.google.com/uc?export=download&id=1n-8P7nEAabbjLD9Fzp_7FjxAOFkYxWB9`,
        size: `121,9 MB`,
      },
      {
        title: `Video: Aftermovie`,
        url: `https://drive.google.com/uc?export=download&id=1F9pJ6ORALkyO22rr56v4C8ZbtBJkLMbF`,
        size: `149,2 MB`,
      },
    ],
  },
  {
    title: `Gala`,
    eventID: `gala`,
    color: `gala`,
    date: "16 dec 2017",
    videos: [
      {
        title: `Aftermovie`,
        id: `X1sTi25LICA`,
        placeholderVid: "/placeholder-vids/gala_loop.mp4",
        placeholderThumb: "/placeholder-vids/Gala.jpg",
      },
    ],
    downloads: [
      {
        title: `Alle foto's`,
        url: `https://drive.google.com/uc?export=download&id=1YdpX2DvccPLFRowITwkfgGEPQyAqu_Ie`,
        size: `12,71 GB`,
      },
      {
        title: `Video: Aftermovie`,
        url: `https://drive.google.com/uc?export=download&id=1z-PTlu0e3bXVWmbalBTd1RtLxsssZ60Q`,
        size: `654,9 MB`,
      },
    ],
  },
  {
    title: `Wintersport`,
    eventID: `wispo`,
    color: `wispo`,
    date: "2-11 feb 2018",
    videos: [
      {
        title: `PitStopParty Roda JC`,
        id: `kl6zrdr48Ks`,
        placeholderVid: "/placeholder-vids/pitstopparty_loop.mp4",
        placeholderThumb: "/placeholder-vids/Pitstopparty.jpg",
      },
      {
        title: `Aftermovie`,
        id: `xk_WgpnSfvY`,
        placeholderVid: "/placeholder-vids/wispo_loop.mp4",
        placeholderThumb: "/placeholder-vids/Wintersport.jpg",
      },
    ],
    downloads: [
      {
        title: `Alle foto's`,
        url: `https://drive.google.com/uc?export=download&id=10M0xR7ga1JGhu8tyoZw8qJ36P6ZGh8qu`,
        size: `8,93 GB`,
      },
      {
        title: `Video: PitStopParty`,
        url: `https://drive.google.com/uc?export=download&id=1ZeIUpb2BugUZVvhIVVms7SKqyaHN5ygf`,
        size: `72,2 MB`,
      },
      {
        title: `Video: Aftermovie`,
        url: `https://drive.google.com/uc?export=download&id=1erRaGPKxWa9iYIxddpUPRlkf6d1qfLkj`,
        size: `1,09 GB`,
      },
    ],
  },
  {
    title: `Lustrumweek`,
    eventID: `lustrumweek`,
    color: `lustrumweek`,
    date: "6-12 mei 2018",
    videos: [
      {
        title: `Aftermovie`,
        id: `qqUn8B5z0z0`,
        placeholderVid: "/placeholder-vids/lustrumweek_loop.mp4",
        placeholderThumb: "/placeholder-vids/Lustrumweek.jpg",
      },
    ],
    downloads: [
      {
        title: `Alle foto's`,
        url: `https://drive.google.com/uc?export=download&id=1DPt9v0-O7FwziVJkoc0PW1EHjvbKOaJZ`,
        size: `7,22 GB`,
      },
      {
        title: `Video: Aftermovie`,
        url: `https://drive.google.com/uc?export=download&id=1n9KK7KFj-Ij2JBRyo7TOrSSzCqqR7nFL`,
        size: `1,37 GB`,
      },
    ],
  },
  {
    title: `Lustrum Productie`,
    eventID: `theater`,
    color: `theater`,
    date: "11 jun 2018",
    videos: [
      {
        title: `Aftermovie`,
        id: `xs7GgVPXuus`,
        placeholderVid: "/placeholder-vids/shrek_loop.mp4",
        placeholderThumb: "/placeholder-vids/Shrek.jpg",
      },
    ],
    downloads: [
      {
        title: `Alle foto's`,
        url: `https://drive.google.com/uc?export=download&id=1gMOao-6nhms-i6Pp5qP8XDHQlt75ScoC`,
        size: `1,02 GB`,
      },
      {
        title: `Video: Aftermovie`,
        url: `https://drive.google.com/uc?export=download&id=18PPz9nVQb4B8c5iOoYw_gmYBgyIwLp7q`,
        size: `476,3 MB`,
      },
    ],
  },
  {
    title: `Piekweek`,
    eventID: `piekweek`,
    color: `piekweek`,
    date: "13-22 jul 2018",
    videos: [
      {
        title: `Locatie Bekendmaking`,
        id: `9qhZK-F1-xE`,
        placeholderVid: "/placeholder-vids/locatie-bekendmaking_loop.mp4",
        placeholderThumb: "/placeholder-vids/Terreinbekendmaking.jpg",
      },
      {
        title: `Aftermovie`,
        id: `3bObBCtdADU`,
        placeholderVid: "/placeholder-vids/piekweek_loop.mp4",
        placeholderThumb: "/placeholder-vids/Piekweek.jpg",
      },
    ],
    downloads: [
      {
        title: `Alle foto's`,
        url: `https://drive.google.com/uc?export=download&id=16xWjZuqK9jMHQYD-_f7KMv8TYt0EmvN9`,
        size: `15,39 GB`,
      },
      {
        title: `Video: Locatie Bekendmaking`,
        url: `https://drive.google.com/uc?export=download&id=16gGWWlRRnqK4d0zuYZ4W1aFTCdPKPBc8`,
        size: `1,23 GB`,
      },
      {
        title: `Video: Aftermovie`,
        url: `https://drive.google.com/uc?export=download&id=15X0rH5zsBdwaFCjb2mEXcxBsGKt6uqNt`,
        size: `2,89 GB`,
      },
    ],
  },
  {
    title: `Revival Dixo`,
    eventID: `revival`,
    color: `black`,
    date: "23 okt 2018",
    videos: [
      {
        title: `Lustrumboek Onthulling`,
        id: `nnA1spGM-xY`,
        placeholderVid: "/placeholder-vids/boek-onthulling_loop.mp4",
        placeholderThumb: "/placeholder-vids/RevivalDixo.jpg",
      },
    ],
    downloads: [
      {
        title: `Alle foto's`,
        url: `https://drive.google.com/uc?export=download&id=11m5sdkS2MNuII_kplaGKQJyM791I4csv`,
        size: `691,6 MB`,
      },
      {
        title: `Video: Onthulling`,
        url: `https://drive.google.com/uc?export=download&id=1-YIedoum1jjEScRkrSVAd3cL7AuZG5Vl`,
        size: `205,1 MB`,
      },
    ],
  },
];
